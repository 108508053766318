<template>
  <persistence-template
    :acne-discomfort-frequency="acneDiscomfortFrequency"
    :first-acne-occurrence="firstAcneOccurrence"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PersistenceTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/persistence/PersistenceTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('acneDiscomfortFrequency'), requiredField('firstAcneOccurrence')];

export default {
  name: 'Persistence',
  components: {
    PersistenceTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    acneDiscomfortFrequency(newValue, oldValue) {
      if (!oldValue && this.firstAcneOccurrence) {
        this.showNextStep();
      } else {
        this.scrollTo('#first-acne-occurrence');
      }
    },
    firstAcneOccurrence(newValue, oldValue) {
      if (!oldValue && this.acneDiscomfortFrequency) {
        this.showNextStep();
      }
    }
  }
};
</script>
